import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LandLordEN from './translate/en/Landlord.json';
import LandLordAR from './translate/ar/Landlord.json';


const combinedEN = {
  ...LandLordEN
};

const combinedAR = {
  ...LandLordAR
};

const resources = {
    en : {
        translation: combinedEN
    },
    ar : {
        translation: combinedAR
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: ['en', 'ar'],
    interpolation: {
        escapeValue: false
    }
  });

export default i18n;
