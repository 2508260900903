/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Header.scss";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import {
  closeMenuIcon,
  facebookBlackIcon,
  hamburgerMenu,
  instagramBlackIcon,
  linkedinBlackIcon,
  silkhausLogoHeader,
  twitterBlackIcon,
} from "../../../helpers/cdn";
import { GlobalContext } from "../../../CommonComponent/GlobalContext";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingBreakfastService,
  update_booking_details_cal,
} from "../../../redux/proparty";
import {countryNames, dashCased, topNavBarAllowedPaths} from "../../../helpers/utils";
import {
  useBookingCode,
  useBookingDetails,
  useCurrentPage,
  useIsBackButtonEnabled,
  useSetCurrentPage,
} from "../../../Components/PropertyCheckout/store/PaymentCheckoutStore";
import { PROPERTY_PAYMENT_PAGE } from "../../../utils/constants";
import { AlertDialog, Dialog, TwoColumnDialog, TopNavContainerMobile, TopNavBar, AnimatedModal
 } from "design-system-silkhaus";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  whatsappLogoIcon,
  topNavBarCalendarMobile,
  topNavBarCalendarDesktop
} from "../../../helpers/cdn"
import useSkywards from "../../../hooks/useSkywards";

const Header = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const {
    state: { city: globalCity },
  } = useContext(GlobalContext);

  const { update_booking_details_data, booking_data } = useSelector(
    (s) => s.propartyDetails
  );
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles({ expanded });
  const dispatchs = useDispatch();

  const handleExpand = () => setExpanded(!expanded);
  const handleClose = () => {
    dispatchs(setBookingBreakfastService(false));
    setExpanded(false);
  };
  const { state, dispatch } = useContext(GlobalContext);

  const booking_code = useBookingCode();

  const handleChngCurr = (qry, pictr) => {
    // console.log({ qry, pictr });
    dispatch({ type: "currency", payload: qry });
    dispatch({ type: "pic", payload: pictr });

    localStorage.setItem("query", qry);
    localStorage.setItem("pic", pictr);
  };

  const ref = useRef(null);

  if (expanded) {
    document.body.classList.add("width_bg");
  } else {
    document.body.classList.remove("width_bg");
  }

  const corporateMenuPathList = [
    "/corporate-and-groups/corporate-inquiry",
    "/corporate-and-groups/group-inquiry",
    "/corporate-and-groups",
  ];

  const dropdownMenuPathList = ["/who-we-are", "/how-it-works"];
  const dropdownDiscoverMenuPathList = ["/featured-towers/marina-vista"];

  const currentPage = useCurrentPage();
  const setCurrentPage = useSetCurrentPage();
  const bookingDetails = useBookingDetails();
  const isBackButtonEnabled = useIsBackButtonEnabled();

  const [showBackModal, setShowBackModal] = useState(false);
  const [showPromotionalDialog, setShowPromotionalDialog] = useState(false);

  const handlePrimaryBtnClick = useCallback(() => {
    setShowBackModal(false);
    setCurrentPage("");
    if (
      bookingDetails?.pms_listing_id &&
      bookingDetails?.check_in_date &&
      bookingDetails?.check_out_date
    ) {
      setIsBackButtonClicked(true);
      const data = {
        booking_code: booking_code,
        is_back_button_triggered: true,
      };
      dispatchs(update_booking_details_cal(data));
    }
  }, [
    navigate,
    bookingDetails?.pms_listing_id,
    dispatchs,
    update_booking_details_cal,
    booking_code,
  ]);

  useEffect(() => {
    if (isBackButtonClicked) {
      if (update_booking_details_data) {
        window.location = booking_data?.data?.metadata?.back_to_listing;
      } else if (bookingDetails) {
        navigate(
          `/property-detail/${bookingDetails?.pms_listing_id}?checkin=${bookingDetails?.check_in_date}&checkout=${bookingDetails?.check_out_date}`
        );
      }
    }

    return () => {
      setIsBackButtonClicked(false);
    };
  }, [update_booking_details_data, booking_data, bookingDetails]);

  const handleBackButtonClick = useCallback(() => {
    setShowBackModal(true);
  }, [setShowBackModal]);

  const BackButton = useCallback(() => {
    return (
      <>
        <button
          onClick={handleBackButtonClick}
          className="tw-bg-transparent tw-text-Background-accentEggplant-100 tw-flex tw-flex-row tw-justify-start tw-items-center tw-text-mediumBodyEmphasized"
        >
          <img src={"https://cdn.silkhaus.com/icons/icon_arrow_left_purple.svg"} alt="back" className="tw-me-[10px]" /> Back
        </button>
      </>
    );
  }, []);

  const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
      localStorage.setItem("preferredLanguage", lang);
  };
  
  const handlePromotionalClick = () => {
      setShowPromotionalDialog(true);
  };

  const closePromotionalDialog = () => {
    setShowPromotionalDialog(false);
  };

  const handlehandlePromotionalDialogButtonClick = () => {
    window.open('https://api.whatsapp.com/send/?phone=971521242483&text=Hi!+I+am+looking+for+a+monthly+Stay+-+Can+you+help+me?&type=phone_number&app_absent=0', "_blank")
  };

  useSkywards();

  return (
    <>
      <div className={classes.screen}>
       {topNavBarAllowedPaths.some(path => pathname.startsWith(path)) && <TopNavBar text='Looking for 30+ day stays?  Get in touch now' onNavBarClick={handlePromotionalClick} isMarquee={false}/> }
        <div className={classes.main}>
          <div className={classes.container}>
            <div style={{ display: "flex", flex: 1 }}>
              <header className={clsx("page_header", currentPage === PROPERTY_PAYMENT_PAGE && !isBackButtonEnabled && "hide")} style={{ flex: 1 }}>
                <div className="openmobMenu">
                  <div className="barnd-logo">
                    {currentPage === PROPERTY_PAYMENT_PAGE ? (
                      <>{isBackButtonEnabled && <BackButton />}</>
                    ) : (
                      <Link
                        to={`/${dashCased(globalCity)}`}
                        onClick={handleClose}
                      >
                        {" "}
                        <img
                          width="94px"
                          height="16px"
                          src={silkhausLogoHeader}
                          alt="Silkhaus"
                        />
                      </Link>
                    )}
                  </div>
                  <div className="menubar">
                    {currentPage === PROPERTY_PAYMENT_PAGE ? (
                      <></>
                    ) : expanded ? (
                      <button onClick={handleClose}>
                        <img src={closeMenuIcon} alt="close menu" />
                      </button>
                    ) : (
                      <>
                        <button onClick={handleExpand}>
                          <img src={hamburgerMenu} alt="open menu" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
                {expanded && (
                  <div className={topNavBarAllowedPaths.some(path => pathname.startsWith(path)) ?"mobmenu-show mobmenu-show--top" : "mobmenu-show"}>
                    <ul className="main-menu">
                      <li>
                        <NavLink
                          onClick={handleClose}
                          to={`our-properties`}
                          className={`nav-link ${
                            pathname === "/our-properties" && "link-active"
                          }`}
                        >
                          Our Properties
                        </NavLink>
                      </li>
                      <li>
                        <Link
                          onClick={handleClose}
                          to={`landlord`}
                          className={`nav-link ${
                            pathname === "/landlord" && "link-active"
                          }`}
                        >
                          Property Owners
                        </Link>
                      </li>

                      <li>
                        <Link
                          onClick={handleClose}
                          to={`corporate-and-groups`}
                          className={`nav-link ${
                            corporateMenuPathList.includes(pathname) &&
                            "link-active"
                          }`}
                        >
                          Corporate & Groups
                        </Link>
                      </li>
                      <li>
                        <NavLink
                          onClick={handleClose}
                          to={`manage-booking`}
                          className={`nav-link ${
                            pathname === "/manage-booking" && "link-active"
                          }`}
                        >
                          My Bookings
                        </NavLink>
                      </li>
                      <li>
                        <Link
                          onClick={handleClose}
                          to={`featured-towers/marina-vista`}
                          className={`nav-link ${
                            pathname === "/featured-towers/marina-vista" &&
                            "link-active"
                          }`}
                        >
                          Marina Vista By Emaar
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={handleClose}
                          to={`who-we-are`}
                          className={`nav-link ${
                            pathname === "/who-we-are" && "link-active"
                          }`}
                        >
                          Who We Are
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={handleClose}
                          to={`how-it-works`}
                          className={`nav-link ${
                            pathname === "/how-it-works" && "link-active"
                          }`}
                        >
                          How It Works
                        </Link>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          href="https://blogs.silkhaus.com"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="blog"
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          href="https://app.zenats.com/en/careers_page/2084-silkhaus"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="careers"
                        >
                          Careers
                        </a>
                      </li>
                      <li>
                        <div className="country-bar" style={{position: 'relative'}}>
                          <NavDropdown
                            align={"end"}
                            title={
                              <>
                                <img
                                  src={state?.pic}
                                  height="13"
                                  width="20"
                                  alt=""
                                />
                              </>
                            }
                            id="collasible-nav-dropdown"
                            // onClick={() => {
                            //   setExpandedNav(true);
                            // }}
                            active={state?.query}
                          >
                            {countryNames.map((item, i) => (
                              <NavDropdown.Item
                                eventKey={item.name}
                                key={i}
                                onClick={(e) => {
                                  handleChngCurr(item.name, item.image);
                                  handleClose();
                                }}
                              >
                                <img src={item.image} alt="" />
                                <span
                                  style={{
                                    color:
                                      state?.query === item.name && "black",
                                    fontWeight:
                                      state?.query === item.name && "800",
                                  }}
                                >
                                  {item.name}
                                </span>
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        </div>
                      </li>
                      {
                          pathname.includes('/landlord') && 
                          (
                          <li>
                              <NavDropdown
                                  style={{fontSize: '16px'}}
                                  className="language-bar" 
                                  title={localStorage.getItem("preferredLanguage") ? localStorage.getItem("preferredLanguage").toLocaleUpperCase() : 'EN'}
                              >
                                  <NavDropdown.Item
                                    eventKey='English'
                                    onClick={() => handleChangeLanguage('en')}
                                  >
                                      English
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    eventKey='Arabic'
                                    onClick={() => handleChangeLanguage('ar')}
                                  >
                                      Arabic
                                  </NavDropdown.Item>
                              </NavDropdown>
                          </li>
                          )
                      }
                      <li>
                        <div className="menu-social">
                          <h3>Follow us</h3>
                          <ul>
                            <li>
                              <a
                                href={process.env.REACT_APP_FB_LINK}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={facebookBlackIcon} alt="" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={process.env.REACT_APP_LIN_LINK}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={linkedinBlackIcon} alt="" />
                              </a>
                            </li>
                            {/* <li>
                        <a>
                          <img src={youtube} alt="" />
                        </a>
                      </li> */}
                            <li>
                              <a
                                href={process.env.REACT_APP_TW_LINK}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={twitterBlackIcon} alt="" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={process.env.REACT_APP_INS_LINK}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={instagramBlackIcon} alt="" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {currentPage === PROPERTY_PAYMENT_PAGE ? (
                  isBackButtonEnabled && (
                    <div className="desktop-back-button">
                      <BackButton />
                    </div>
                  )
                ) : (
                  <div className=" desktop-menu">
                    <Navbar collapseOnSelect expand="lg" variant="dark">
                      <Navbar.Brand>
                        <NavLink to={`/${dashCased(globalCity)}`}>
                          <img
                            width="94px"
                            height="16px"
                            src={silkhausLogoHeader}
                            alt=""
                          />
                        </NavLink>
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse ref={ref} id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                          <NavLink
                            to={`our-properties`}
                            className={`nav-link ${
                              pathname === "/our-properties" && "link-active"
                            }`}
                          >
                            Our Properties
                          </NavLink>
                          <div id="dropdown-list">
                            <NavDropdown
                              title="Discover"
                              id="basic-nav-dropdown"
                              className={
                                dropdownDiscoverMenuPathList.includes(pathname)
                                  ? "link-active"
                                  : ""
                              }
                            >
                              <NavDropdown.Item
                                as={Link}
                                to={"/featured-towers/marina-vista"}
                                active={
                                  pathname === "/featured-towers/marina-vista"
                                }
                              >
                                Marina Vista By Emaar
                              </NavDropdown.Item>
                            </NavDropdown>
                          </div>

                          <NavLink
                            to={`landlord`}
                            className={`nav-link ${
                              pathname === "/landlord" && "link-active"
                            }`}
                          >
                            Property Owners
                          </NavLink>

                          <NavLink
                            to={`corporate-and-groups`}
                            className={`nav-link ${
                              corporateMenuPathList.includes(pathname) &&
                              "link-active"
                            }`}
                          >
                            Corporate & Groups
                          </NavLink>
                          <NavLink
                            onClick={handleClose}
                            to={`manage-booking`}
                            className={`nav-link ${
                              pathname === "/manage-booking" && "link-active"
                            }`}
                          >
                            My Bookings
                          </NavLink>
                          <div id="dropdown-list">
                            <NavDropdown
                              title="About Us"
                              id="basic-nav-dropdown"
                              className={
                                dropdownMenuPathList.includes(pathname)
                                  ? "link-active"
                                  : ""
                              }
                            >
                              <NavDropdown.Item
                                as={Link}
                                to={"/who-we-are"}
                                active={pathname === "/who-we-are"}
                              >
                                Who We Are
                              </NavDropdown.Item>

                              <NavDropdown.Item
                                as={Link}
                                to={"/how-it-works"}
                                active={pathname === "/how-it-works"}
                              >
                                How It Works
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={Link}
                                target="_blank"
                                to="https://blogs.silkhaus.com"
                              >
                                Blog
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={Link}
                                target="_blank"
                                to="https://app.zenats.com/en/careers_page/2084-silkhaus"
                              >
                                Careers
                              </NavDropdown.Item>
                            </NavDropdown>
                          </div>
                          <NavDropdown
                            title={
                              <>
                                <img
                                  src={state?.pic}
                                  height="13"
                                  width="20"
                                  alt=""
                                />
                              </>
                            }
                            id="collasible-nav-dropdown"
                            active={state?.query}
                          >
                            {countryNames.map((item, i) => (
                              <NavDropdown.Item
                                eventKey={item.name}
                                key={i}
                                onClick={(e) => {
                                  handleChngCurr(item.name, item.image);
                                }}
                              >
                                <img src={item.image} alt="" />
                                <span
                                  style={{
                                    color:
                                      state?.query === item.name && "black",
                                    fontWeight:
                                      state?.query === item.name && "800",
                                  }}
                                >
                                  {item.name}
                                </span>
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                          {
                            pathname.includes('/landlord') && 
                              (
                              <NavDropdown
                                  style={{marginLeft : '1rem'}}
                                  title={localStorage.getItem("preferredLanguage") ? localStorage.getItem("preferredLanguage").toLocaleUpperCase() : 'EN'}
                              >
                                  <NavDropdown.Item
                                    eventKey='English'
                                    onClick={() => handleChangeLanguage('en')}
                                  >
                                      English
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    eventKey='Arabic'
                                    onClick={() => handleChangeLanguage('ar')}
                                  >
                                      Arabic
                                  </NavDropdown.Item>
                              </NavDropdown>
                              )
                          }
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                )}
              </header>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showBackModal}>
        <AlertDialog
          banner={"https://cdn.silkhaus.com/icons/Size-Desktop-Content-GoBack.jpg"}
          title="Are you sure you want to go back ?"
          description="You will have to start the reservation process again."
          primaryBtnText="Go Back"
          secondaryBtnText="Cancel"
          onPrimaryBtnClick={handlePrimaryBtnClick}
          onSecondaryBtnClick={() => setShowBackModal(false)}
          showPrimaryBtn={true}
          showSecondaryBtn={true}
        />
      </Dialog>

      <AnimatedModal show={showPromotionalDialog} handleClose={closePromotionalDialog} animation='slideDown' maxWidth='488px' marginTop='150px'>
          <div className="tw-hidden tablet:tw-block">
            <TwoColumnDialog 
              img={topNavBarCalendarDesktop}
              handleDialogButtonClick={handlehandlePromotionalDialogButtonClick}
              dialogHeader="Staying for 30+ days?"
              dialogDescription="Let us help you find the best rates for Monthly Stays."
              buttonIcon={whatsappLogoIcon}
              buttonText="Get in touch"
            />
          </div>
          <div className="tablet:tw-hidden">
            <TopNavContainerMobile
                img={topNavBarCalendarMobile}
                handleDialogButtonClick={handlehandlePromotionalDialogButtonClick}
                handleCloseButtonClick={closePromotionalDialog}
                dialogHeader="Staying for 30+ days?"
                dialogDescription="Let us help you find the best rates for Monthly Stays."
                buttonIcon={whatsappLogoIcon}
                buttonText="Get in touch"
            />
          </div>
      </AnimatedModal>  
    </>
  );
};

const useStyles = createUseStyles({
  screen: {
    position: "fixed",
    left: 0,
    right: 0,
    width: "100vw",
    backgroundColor: "white",
    zIndex: 999,
  },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  container: {
    maxWidth: "100%",
    flex: 1,
    display: "flex",
    padding: 0,

    "@media (min-width: 1000px)": {
      padding: "0px 50px 0 50px",
    },

    "@media (min-width:1536px)": {
      maxWidth: "1536px",
      padding: "0px 0px 0 0px",
    },
  },
  content: {
    flex: 1,
  },
});

export default Header;
